import { useMemo } from "react";
import VideoJS from "../VideoJS";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import { NamedEntity } from "../../models/NamedEntity";

type LiveCardProps = {
	cliente?: NamedEntity;
	drone: NamedEntity;
};

export default function LiveCard(props: LiveCardProps) {
	const navigate = useNavigate();

	const videoJsOption = useMemo(() => {
		return {
			autoplay: true,
			playsinline: true,
			controls: true,
			responsive: true,
			liveui: true,
			fill: true,
			sources: `http://mp2.air.tec.br:3381/hls/${props.drone.id}/index.m3u8`,
			poster: process.env.PUBLIC_URL + "/logo_escuro.png",
		};
	}, [props.drone]);

	function handleClick() {
		navigate(`/live/${encodeURI(props.drone.nome)}/${props.drone.id}`);
	}

	return (
		<div className="position-relative img-thumbnail" style={{ width: "100%", height: "100%" }}>
			<Button variant="light" className="d-flex align-items-center mt-1 me-2 position-absolute" onClick={handleClick} style={{ zIndex: 10, right: 0 }}>
				{props.cliente && `${props.cliente.nome} - `}
				{props.drone.nome}
				<Icon path={mdiChevronRight} size={1} className="d-inline-block" />
			</Button>
			<VideoJS options={videoJsOption} className="bg-dark" style={{ width: "100%", height: "100%" }} />
		</div>
	);
}
