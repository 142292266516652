import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import moment from "moment";
import Icon from "@mdi/react";
import { mdiBroadcastOff } from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import ApiService from "../../services/ApiService";
import { gridTemplate } from "../../config/defines";
import { ApiResponseType } from "../../entities/ApiResponseEntity";
import { StatusVooModel } from "../../models/StatusVooModel";
import { NamedEntity } from "../../models/NamedEntity";

import LiveCard from "../../components/LiveCard";
import Layout from "../../components/Layout";

export default function LivesAdmin() {
	const navigate = useNavigate();
	const { handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);
	const dateEnd = moment();

	const [modal, setModal] = useState(true);
	const [senha, setSenha] = useState("");
	const [message, setMessage] = useState("");
	const [admin, setAdmin] = useState(false);

	const queryClientes = useQuery(["cadastros", "clientes"], () => fetchData(), { enabled: admin, refetchOnWindowFocus: false });
	const queryClientesDrones = useQuery(["drones"], () => fetchDataDrones(), { enabled: admin && queryClientes.isFetched, refetchOnWindowFocus: false });
	const queryDroneStatusVoo = useQuery(["drones", "statusVoos"], () => fetchDataDronesStatusVoo(), {
		enabled: admin && queryClientesDrones.isFetched,
		refetchOnWindowFocus: false,
		refetchInterval: 10000,
	});

	async function fetchData() {
		return await apiService.GetClientes();
	}

	async function fetchDataDrones() {
		let resp: { cliente: NamedEntity; drones: ApiResponseType<NamedEntity[]> }[] = [];
		if (queryClientes.data?.Data) {
			await Promise.all(
				queryClientes.data?.Data.map(async (cliente) => {
					let respDrones = await apiService.GetDronesCliente(cliente.id);
					resp[cliente.id] = { cliente: cliente, drones: respDrones };
				})
			);
		}
		return resp;
	}

	async function fetchDataDronesStatusVoo() {
		let resp: { cliente: NamedEntity; drone: NamedEntity; statusVoo: ApiResponseType<StatusVooModel> }[] = [];
		if (queryClientesDrones.data) {
			await Promise.all(
				queryClientesDrones.data.map(async (clienteDrones) => {
					if (clienteDrones.drones.Data) {
						await Promise.all(
							clienteDrones.drones.Data.map(async (drone) => {
								let respDroneStatusVoo = await apiService.getDroneStatusVoo(drone.id, clienteDrones.cliente.id);
								resp[drone.id] = { cliente: clienteDrones.cliente, drone: drone, statusVoo: respDroneStatusVoo };
							})
						);
					}
				})
			);
		}
		return resp;
	}

	function handleAcessar(e: any) {
		e.preventDefault();
		e.stopPropagation();
		setMessage("");
		if (senha === "a4b") {
			setAdmin(true);
			setModal(false);
		} else {
			setMessage("Senha inválida");
		}
	}

	function handleClose() {
		navigate("/");
		setModal(false);
	}

	const lives = queryDroneStatusVoo.data?.filter((data) => {
		if (data === undefined || data.statusVoo === undefined) {
			return false;
		}

		let dateStatus = false;
		if (data.statusVoo.Data?.data?.ticks) {
			let dateStart = moment(data.statusVoo.Data.data.ticks);
			let hours = moment.duration(dateEnd.diff(dateStart)).asHours();
			if (hours < 24) {
				dateStatus = true;
			}
		}
		return data.statusVoo.Data && dateStatus && data.statusVoo.Data.voando === 1;
	});
	const livesGrid = gridTemplate(lives?.length ?? 0);

	return (
		<Layout cliente={false}>
			<div className="my-4" style={{ display: "grid", gridTemplateColumns: livesGrid.gridTemplateColumns, minHeight: "calc(100dvh - 58px - 3rem)" }}>
				{!admin || queryClientes.isLoading || queryClientesDrones.isLoading || queryDroneStatusVoo.isLoading ? (
					<div className="d-flex justify-content-center align-items-center">
						<Spinner />
					</div>
				) : lives && lives.length >= 1 ? (
					lives.map((data, index) => {
						return (
							<div key={data.drone.id} style={{ gridColumn: livesGrid.gridColumns[index] }}>
								<LiveCard drone={data.drone} cliente={data.cliente} />
							</div>
						);
					})
				) : (
					<Alert variant="light" className="text-center p-4 d-flex flex-column justify-content-center align-items-center">
						<Icon path={mdiBroadcastOff} size={4} className="me-2" />
						Nenhuma Live no momento.
						<span className="text-muted mt-3 small">Quando houver Lives, elas serão exibidas automaticamente.</span>
					</Alert>
				)}
			</div>

			<Modal show={modal} centered>
				<Form onSubmit={handleAcessar}>
					<Modal.Header>
						<Modal.Title>Lives Admin</Modal.Title>
					</Modal.Header>
					<Modal.Body className="text-center">
						<Form.Control
							type="password"
							placeholder="Informe aqui a senha"
							size="lg"
							autoFocus={true}
							value={senha}
							onChange={(event) => {
								setSenha(event.target.value);
							}}
						/>
						{message && <Alert variant="danger mt-3 mb-0">{message}</Alert>}
					</Modal.Body>

					<Modal.Footer>
						<Button variant="secondary" type="button" onClick={handleClose}>
							Voltar
						</Button>
						<Button variant="air-blue" type="submit" className="text-white">
							Acessar
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Layout>
	);
}
