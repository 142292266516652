import { useEffect, useMemo, useRef, useState } from "react";
import { Badge, Button, Card, Col, Form, Image, Modal, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import GoogleMap, { Map } from "google-maps-react-markers";
import moment from "moment";
import Draggable from "react-draggable";
import Icon, { Stack } from "@mdi/react";
import {
	mdiAirplane,
	mdiAlphaHCircleOutline,
	mdiArrowExpand,
	mdiChevronLeft,
	mdiCircle,
	mdiCursorMove,
	mdiDownloadOutline,
	mdiMinusCircle,
	mdiPlayCircle,
	mdiSwapVerticalCircle,
	mdiTrashCanOutline,
} from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ApiService from "../../services/ApiService";

import Layout from "../../components/Layout";
import Marker from "../../components/Marker";
import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconLogo } from "../../icons/logo_icone.svg";
import { StatusVooCoordsModel, StatusVooModel } from "../../models/StatusVooModel";
import { ContentDispositionHelpers } from "../../utils/ContentDispositionHelpers";
import VideoJS from "../../components/VideoJS";
import Player from "video.js/dist/types/player";
import { MissaoLogImagensModel } from "../../models/MissaoLogModel";

const toastTitle = "Voo";

type GelariaType = {
	tipo: "video" | "foto";
	data: MissaoLogImagensModel | null;
};

export default function Voo() {
	const navigate = useNavigate();
	const { handleToast } = useToast();
	const { cliente, handleLogout } = useAuth();
	const { missaoLogID } = useParams();
	const apiService = new ApiService(handleLogout);

	const containerRef = useRef<HTMLDivElement>(null);
	const mapRef = useRef<Map>();

	const [exporting, setExporting] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [formHomeLatitude, setFormHomeLatitude] = useState<number>();
	const [formHomeLongitude, setFormHomeLongitude] = useState<number>();
	const [formVertice, setFormVertice] = useState<StatusVooCoordsModel>();
	const [formStatus, setFormStatus] = useState<StatusVooModel>();
	const [pip, setPIP] = useState(false);
	const [size, setSize] = useState(true);
	const [videoPosition, setVideoPosition] = useState({ x: 0, y: 0 });
	const [mapPosition, setMapPosition] = useState({ x: 10, y: 10 });
	const [containerWidth, setContainerWidth] = useState(0);
	const [containerHeight, setContainerHeight] = useState(0);
	const [video, setVideo] = useState<Player>();
	const [galeria, setGaleria] = useState<GelariaType>({ tipo: "video", data: null });

	const [removeModal, setRemoveModal] = useState(false);
	const [removeLoadingModal, setRemoveLoadingModal] = useState(false);

	useQuery(["cliente", "home", cliente!.id], () => fetchDataClienteHome(cliente!.id));
	const queryMissaoDetalhe = useQuery(["missao", missaoLogID, "detalhe"], () => fetchDataMissaoDetalhe(missaoLogID), {
		enabled: !!missaoLogID,
	});
	const queryMissaoImagens = useQuery(["missao", missaoLogID, "imagens"], () => fetchDataMissaoImagens(missaoLogID), {
		enabled: !!missaoLogID,
	});
	useQuery(["voo", "status", cliente!.id, formVertice?.id], () => fetchDataVooStatus(formVertice?.id), {
		enabled: !!formVertice?.id,
	});
	useQuery(["voo", "missao", "home", cliente!.id, queryMissaoDetalhe.data?.Data?.missaoId], () => fetchDataMissaoHome(queryMissaoDetalhe.data?.Data?.missaoId), {
		enabled: !!queryMissaoDetalhe.data?.Data?.missaoId,
	});

	const videoJsOption = useMemo(() => {
		return {
			autoplay: true,
			playsinline: true,
			controls: true,
			responsive: true,
			fill: true,
			sources: `http://mp2.air.tec.br:3381/rec/${queryMissaoDetalhe.data?.Data?.vooId}.mp4`,
			poster: process.env.PUBLIC_URL + "/logo_escuro.png",
		};
	}, [queryMissaoDetalhe.data?.Data?.vooId]);

	useEffect(() => {
		let rota: google.maps.Polygon;
		if (queryMissaoDetalhe.data?.Data && mapRef.current) {
			rota = new google.maps.Polygon({
				paths: queryMissaoDetalhe.data?.Data.status.map((item) => {
					return { lat: item.lat, lng: item.lng };
				}),
				strokeColor: "#FF0000",
				strokeOpacity: 1,
				strokeWeight: 2,
				fillOpacity: 0,
			});
			rota.addListener("click", (event: any) => {
				if (event.latLng) {
					var lat = event.latLng.lat();
					var lng = event.latLng.lng();

					var minVert = null;
					var minDist = Number.MAX_VALUE;
					for (var v of queryMissaoDetalhe.data!.Data!.status) {
						var dist = Math.sqrt((v.lat - lat) ** 2 + (v.lng - lng) ** 2);
						if (dist < minDist) {
							minDist = dist;
							minVert = v;
						}
					}
					if (minVert) {
						var time = (minVert.timestamp - queryMissaoDetalhe.data!.Data!.status.last().timestamp) / 1000;
						video?.currentTime(Math.max(0, time));
					}
				}
			});
			rota.setMap(mapRef.current);

			var bounds = new google.maps.LatLngBounds();
			var paths = rota.getPaths();
			var path;
			if (paths.getLength() >= 1) {
				for (var i = 0; i < paths.getLength(); i++) {
					path = paths.getAt(i);
					for (var ii = 0; ii < path.getLength(); ii++) {
						bounds.extend(path.getAt(ii));
					}
				}
				mapRef.current.fitBounds(bounds);
			}
		}

		return () => {
			if (rota) {
				rota.setMap(null);
			}
		};
		// eslint-disable-next-line
	}, [queryMissaoDetalhe.data, mapRef.current]);

	useEffect(() => {
		function handleTimeupdate() {
			if (queryMissaoDetalhe.data?.Data?.status.last()) {
				let currentTick = Number((Number(queryMissaoDetalhe.data?.Data?.status.last().timestamp) / 1000).toFixed(0)) + Number(Number(video!.currentTime()).toFixed(0));
				let vertice = queryMissaoDetalhe.data?.Data?.status.find((item) => {
					return Number((item.timestamp / 1000).toFixed(0)) === currentTick;
				});
				if (vertice) {
					setFormVertice(vertice);
				}
			}
		}
		if (queryMissaoDetalhe.data?.Data && video) {
			video.on("timeupdate", handleTimeupdate);
		}

		return () => {
			video?.off("timeupdate", handleTimeupdate);
		};
		// eslint-disable-next-line
	}, [queryMissaoDetalhe.data, video]);

	useEffect(() => {
		function handleResize() {
			if (containerRef.current) {
				setContainerWidth(containerRef.current.clientWidth);
				setContainerHeight(containerRef.current.clientHeight);
			}
		}

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
		// eslint-disable-next-line
	}, [containerRef.current]);

	async function fetchDataClienteHome(clienteId: any) {
		let resp = await apiService.getHome(clienteId);
		if (resp.Result === 1 && resp.Data) {
			setFormHomeLatitude(resp.Data.latitude);
			setFormHomeLongitude(resp.Data.longitude);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		return true;
	}

	async function fetchDataMissaoDetalhe(missaoId: any) {
		let resp = await apiService.GetMissaoLogDetalhe(missaoId);
		if (resp.Result === 1 && resp.Data) {
			setFormVertice(resp.Data.status[0]);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}
		return resp;
	}

	async function fetchDataMissaoImagens(missaoId: any) {
		let resp = await apiService.GetMissaoLogImagens(missaoId);
		if (resp.Result !== 1) {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}
		return resp;
	}

	async function fetchDataVooStatus(statusId: any) {
		let resp = await apiService.GetStatusVoo(statusId);
		if (resp.Result === 1 && resp.Data) {
			setFormStatus(resp.Data);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}
		return resp;
	}

	async function fetchDataMissaoHome(missaoId: any) {
		let resp = await apiService.GetHomeMissao(missaoId);
		if (resp.Result === 1 && resp.Data) {
			setFormHomeLatitude(resp.Data.latitude);
			setFormHomeLongitude(resp.Data.longitude);
		}
		return resp;
	}

	function handleMapLoad({ map, maps }: { map: Map; maps: any }) {
		mapRef.current = map;
	}

	function handleVoltar() {
		navigate("/");
	}

	function handleAlterar() {
		setPIP(!pip);
		setVideoPosition(mapPosition);
		setMapPosition(videoPosition);
	}

	function handleSize() {
		setSize(!size);
	}

	async function handleExportar() {
		setExporting(true);
		try {
			var resp: any = await apiService.GetStatusVooCsvLogMissao(missaoLogID!);
			if (resp.status === 200) {
				var blob = resp.data;
				var a = document.createElement("a");
				var url = window.URL.createObjectURL(blob);
				a.href = url;
				var filename = "statusvoo.csv";
				try {
					var cd = resp.headers.get("content-disposition");
					if (cd) {
						var fn = ContentDispositionHelpers.getFilename(cd);
						if (fn.length > 0) filename = fn;
					}
				} catch (e) {
					console.log(e);
				}
				a.download = filename;
				a.click();
				window.URL.revokeObjectURL(url);
			}
		} catch (e) {
			console.log(e);
		}
		setExporting(false);
	}

	function handleVideo() {
		setGaleria({ tipo: "video", data: null });
		mapRef.current?.setCenter({ lat: formVertice!.lat, lng: formVertice!.lng });
	}

	function handleFoto(data: MissaoLogImagensModel) {
		setGaleria({ tipo: "foto", data: data });
		mapRef.current?.setCenter({ lat: data.latitude, lng: data.longitude });
	}

	function handleGaleriaRemoverOpen(e: any) {
		e.stopPropagation();
		setRemoveModal(true);
	}

	function handleGaleriaRemoverClose() {
		setRemoveModal(false);
	}

	async function handleGaleriaRemover() {
		setRemoveLoadingModal(true);
		let resp = await apiService.DeleteMissaoLogImagen(galeria.data!.id);
		if (resp.Result === 1) {
			queryMissaoImagens.refetch();
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}
		setRemoveLoadingModal(false);
		setRemoveModal(false);
	}

	async function handleGaleriaDownload() {
		setDownloading(true);
		fetch(galeria.data!.fileURL)
			.then((response) => response.blob())
			.then((blob) => {
				var url = URL.createObjectURL(blob);

				var a = document.createElement("a");
				a.href = url;
				a.download = `${galeria.data!.originalFileName}`;
				a.click();
				URL.revokeObjectURL(url);
			})
			.catch(() => {
				handleToast(toastTitle, "Problema ao baixar o arquivo, tente novamente mais tarde", 5000, "warning");
			})
			.finally(() => {
				setDownloading(false);
			});
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Link to={"/voos"} className="d-flex text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" />
					<Icon path={mdiAirplane} size={1} className="me-1" />
					Vôo
				</Link>
				{(queryMissaoDetalhe.isLoading || queryMissaoDetalhe.isFetching || queryMissaoDetalhe.isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
			</h5>

			<Row className="mb-1 gap-0">
				<Col>
					<Card className="mb-2">
						{/* <Card.Body style={{ minHeight: 405, aspectRatio: 16 / 9 }}> */}
						<Card.Body style={{ minHeight: 265 }}>
							<div ref={containerRef} className="position-relative" style={{ width: "100%", aspectRatio: 16 / 9, minHeight: 265 }}>
								<Draggable
									handle=".handle"
									position={videoPosition}
									onDrag={(_, position) => {
										setVideoPosition(position);
									}}
									scale={1}
									bounds="parent"
									disabled={!pip}
								>
									<div
										className={`d-inline-block position-absolute ${pip ? "rounded overflow-hidden shadow" : ""}`}
										style={pip ? { width: 210, height: 210, zIndex: 2 } : { width: containerWidth, height: containerHeight, zIndex: 1 }}
									>
										{pip && (
											<div className={`d-flex ${size ? "position-absolute" : ""}  bg-white p-1 shadow-sm rounded small`} style={{ zIndex: 3 }}>
												<div className="handle" style={{ width: 20, cursor: "move" }}>
													<Stack>
														<Icon path={mdiCircle} size={1} />
														<Icon path={mdiCursorMove} size={0.6} className="text-white" />
													</Stack>
												</div>
												<div onClick={handleSize} style={{ width: 20, cursor: "pointer" }}>
													{size ? (
														<Icon path={mdiMinusCircle} size={0.8} />
													) : (
														<Stack>
															<Icon path={mdiCircle} size={1} />
															<Icon path={mdiArrowExpand} size={0.6} className="text-white" />
														</Stack>
													)}
												</div>
												<div onClick={handleAlterar} style={{ width: 20, cursor: "pointer" }}>
													<Icon path={mdiSwapVerticalCircle} size={0.8} />
												</div>
											</div>
										)}
										{galeria.tipo === "video" ? (
											queryMissaoDetalhe.data?.Data?.vooId && (
												<VideoJS
													options={videoJsOption}
													className="bg-dark"
													onReady={(player: Player) => {
														setVideo(player);
													}}
													style={{ width: "100%", height: "100%" }}
												/>
											)
										) : (
											<div className="d-flex flex-column bg-dark" style={{ width: "100%", height: "100%" }}>
												<Image src={galeria.data!.fileURL} className="flex-fill" style={{ height: 0, objectFit: "contain" }} />
											</div>
										)}
									</div>
								</Draggable>
								<Draggable
									handle=".handle"
									position={mapPosition}
									onDrag={(_, position) => {
										setMapPosition(position);
									}}
									scale={1}
									bounds="parent"
									disabled={pip}
								>
									<div
										className={`d-inline-block position-absolute ${!pip ? "rounded overflow-hidden shadow" : ""}`}
										style={!pip ? { width: size ? 210 : "auto", height: size ? 210 : "auto", zIndex: 2 } : { width: containerWidth, height: containerHeight, zIndex: 1 }}
									>
										{!pip && (
											<div className={`d-flex ${size ? "position-absolute" : ""}  bg-white p-1 shadow-sm rounded small`} style={{ zIndex: 3 }}>
												<div className="handle" style={{ width: 20, cursor: "move" }}>
													<Stack>
														<Icon path={mdiCircle} size={1} />
														<Icon path={mdiCursorMove} size={0.6} className="text-white" />
													</Stack>
												</div>
												<div onClick={handleSize} style={{ width: 20, cursor: "pointer" }}>
													{size ? (
														<Icon path={mdiMinusCircle} size={0.8} />
													) : (
														<Stack>
															<Icon path={mdiCircle} size={1} />
															<Icon path={mdiArrowExpand} size={0.6} className="text-white" />
														</Stack>
													)}
												</div>
												<div onClick={handleAlterar} style={{ width: 20, cursor: "pointer" }}>
													<Icon path={mdiSwapVerticalCircle} size={0.8} />
												</div>
											</div>
										)}
										<div className={`${!size ? "d-none" : ""}`} style={{ width: "100%", height: "100%" }}>
											{formVertice?.lat && formVertice?.lng && (
												<GoogleMap
													apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
													defaultZoom={14}
													defaultCenter={
														galeria.tipo === "video" ? { lat: formVertice?.lat!, lng: formVertice?.lng! } : { lat: galeria.data!.latitude, lng: galeria.data!.longitude }
													}
													options={{ fullscreenControl: false, streetViewControl: false, zoomControlOptions: { position: 21 }, mapTypeControlOptions: { position: 20 } }}
													onGoogleApiLoaded={handleMapLoad}
													style={{ height: "100%" }}
												>
													<Marker lat={formHomeLatitude} lng={formHomeLongitude}>
														<Icon
															path={mdiAlphaHCircleOutline}
															size={1.5}
															className="text-danger bg-white rounded-circle"
															style={{ filter: "drop-shadow( 0 0 2px rgba(0, 0, 0, 0.5))" }}
														/>
													</Marker>
													<Marker
														lat={galeria.tipo === "video" ? formVertice?.lat : galeria.data!.latitude}
														lng={galeria.tipo === "video" ? formVertice?.lng : galeria.data!.longitude}
													>
														<IconSVG path={IconLogo} style={{ width: 35, height: 35, filter: "drop-shadow( 0 0 4px rgba(0, 0, 0, 1))" }} />
													</Marker>
												</GoogleMap>
											)}
										</div>
									</div>
								</Draggable>
							</div>
							{galeria.tipo === "foto" && (
								<div className="d-flex flex-column bg-dark">
									<div className="d-flex justify-content-between align-items-center gap-2 text-white flex-wrap py-1 px-2">
										<div className="d-flex gap-1 small">
											<span className="fw-lighter">Altura:</span>
											<span>
												{galeria.data!.height.toFixed(2)} <span className="small">m</span>
											</span>
										</div>
										<div className="d-flex gap-1 small">
											<span className="fw-lighter">Latitude:</span>
											<span>{galeria.data!.latitude}</span>
										</div>
										<div className="d-flex gap-1 small">
											<span className="fw-lighter">Longitude:</span>
											<span>{galeria.data!.longitude}</span>
										</div>
										<div className="d-flex gap-2 small">
											<Button
												variant="light"
												size="sm"
												className="d-flex align-items-center py-1 px-2"
												onClick={handleGaleriaDownload}
												disabled={downloading}
												style={{ height: 26 }}
											>
												{downloading ? <Spinner size="sm" className="me-2" /> : <Icon path={mdiDownloadOutline} style={{ width: "1rem", height: "1rem" }} />}
											</Button>
											<Button variant="light" size="sm" className="d-flex align-items-center py-1 px-2" onClick={handleGaleriaRemoverOpen} style={{ height: 26 }}>
												<Icon path={mdiTrashCanOutline} style={{ width: "1rem", height: "1rem" }} className="text-danger" />
											</Button>
										</div>
									</div>
								</div>
							)}
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} md={"auto"} className="mb-2">
					<Card style={{ height: "100%", minHeight: 134 }}>
						<Card.Body className="d-flex flex-column flex-wrap gap-2 flex-lg-nowrap overflow-auto" style={{ height: 0 }}>
							<div
								className="d-flex align-items-center position-relative"
								onClick={() => {
									handleVideo();
								}}
								style={{ width: 150, cursor: "pointer" }}
							>
								<Image className="rounded border bg-dark" src={process.env.PUBLIC_URL + "/logo_escuro.png"} style={{ width: "100%", aspectRatio: 16 / 9, objectFit: "contain" }} />
								<div className="d-flex align-items-center justify-content-center position-absolute text-white" style={{ inset: 0 }}>
									<Icon path={mdiPlayCircle} size={2} style={{ filter: "drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5)" }} />
								</div>
							</div>
							{queryMissaoImagens.isLoading ? (
								<div className="rounded border d-flex justify-content-center align-items-center" style={{ width: "100%", aspectRatio: 16 / 9 }}>
									<Spinner />
								</div>
							) : (
								queryMissaoImagens.data?.Data?.map((imagem) => {
									return (
										<div
											key={imagem.id}
											className="d-flex align-items-center"
											onClick={() => {
												handleFoto(imagem);
											}}
											style={{ width: 150, cursor: "pointer" }}
										>
											<Image className="rounded border" src={imagem.fileURL} style={{ width: "100%", aspectRatio: 16 / 9, objectFit: "cover" }} />
										</div>
									);
								})
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<Card className="mb-3">
						<Card.Body>
							<Row>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Missão</div>
										<div className={`fw-light ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
											{queryMissaoDetalhe.data?.Data?.missao?.nome}&nbsp;
										</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Piloto</div>
										<div className={`fw-light ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
											{queryMissaoDetalhe.data?.Data?.piloto.nome}&nbsp;
										</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Drone</div>
										<div className={`fw-light ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
											{queryMissaoDetalhe.data?.Data?.drone.nome}&nbsp;
										</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!formVertice ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Data</div>
										<div className={`fw-light ${!formVertice ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
											{moment(formVertice?.timestamp).format("DD/MM/YYYY HH:mm:ss")}
										</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group>
										<div className={`fw-lighter ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Status</div>
										<div className={`d-flex gap-1 fw-light ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>
											{formStatus?.decolando === 1 && <Badge>Decolando</Badge>}
											{formStatus?.pousando === 1 && <Badge>Pousando</Badge>}
											{formStatus?.retornando === 1 && <Badge>Retornando</Badge>}
											{formStatus?.pausado === 1 && <Badge>Pausado</Badge>}
											{formStatus?.voando === 1 && <Badge>Voando</Badge>}
											&nbsp;
										</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!formVertice ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Altura</div>
										<div className={`fw-light ${!formVertice ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
											{formVertice?.altura.toFixed(2)} <span className="text-muted small">m</span>
										</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Velocidade</div>
										<div className={`fw-light ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
											{formStatus?.velocidade.toFixed(2)} <span className="text-muted small">m/s</span>
										</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Temperatura</div>
										<div className={`fw-light ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
											{queryMissaoDetalhe.data?.Data?.temperatura.toFixed(1)} <span className="text-muted small">°C</span>
										</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!formVertice ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Latitude</div>
										<div className={`fw-light ${!formVertice ? "skeleton skeleton-text skeleton-text-text" : ""}`}>{formVertice?.lat}&nbsp;</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!formVertice ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Longitude</div>
										<div className={`fw-light ${!formVertice ? "skeleton skeleton-text skeleton-text-text" : ""}`}>{formVertice?.lng}&nbsp;</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Vento</div>
										<div className={`fw-light ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
											{queryMissaoDetalhe.data?.Data?.vento.toFixed(1)} <span className="text-muted small">km/h</span>
										</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Bateria iPhone</div>
										<div className={`fw-light ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
											{formStatus?.bateriaMobile} <span className="text-muted small">%</span>
										</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Bateria</div>
										<div className={`fw-light ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
											{formStatus?.bateria} <span className="text-muted small">%</span>
										</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Bateria RC</div>
										<div className={`fw-light ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
											{formStatus?.bateriaRc} <span className="text-muted small">%</span>
										</div>
									</Form.Group>
								</Col>
								<Col lg={4}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Sinal GPS</div>
										<div className={`fw-light ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
											{formStatus?.gpsSignal}
											<span className="text-muted small">/5</span>
										</div>
									</Form.Group>
								</Col>
								<Col lg={8}>
									<Form.Group className="mb-1">
										<div className={`fw-lighter ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>GPS (Quantidade)</div>
										<div className={`fw-light ${!queryMissaoDetalhe.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>{formStatus?.gpsCount}&nbsp;</div>
									</Form.Group>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<div className="mb-4">
				<Button variant="dark" type="button" onClick={handleExportar} disabled={exporting}>
					{exporting ? <Spinner size="sm" className="me-2" /> : <Icon path={mdiDownloadOutline} size={1} />}
					Baixar CSV
				</Button>
			</div>

			<Modal show={removeModal} onHide={handleGaleriaRemoverClose} centered>
				<Modal.Header closeButton>
					<Modal.Title>Remover Foto</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-center">{!removeLoadingModal ? "Deseja realmente remover esta foto?" : <Spinner />}</Modal.Body>
				{!removeLoadingModal && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleGaleriaRemoverClose}>
							Cancelar
						</Button>
						<Button variant="danger" onClick={handleGaleriaRemover}>
							Remover
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</Layout>
	);
}
