import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

import { ResumoClienteModel } from "../../models/ResumoClienteModel";
import ListCard from "../ListCard";
import { useAuth } from "../../context/AuthContext";

type ClienteResumoCardPropsContent = {
	data: ResumoClienteModel;
	skeleton?: never;
};

type ClienteResumoCardPropsSkeleton = {
	data?: never;
	onClick?: never;
	onRemove?: never;
	skeleton: true;
};

type ClienteResumoCardProps = ClienteResumoCardPropsContent | ClienteResumoCardPropsSkeleton;

export default function ClienteResumoCard(props: ClienteResumoCardProps) {
	const navigate = useNavigate();
	const { handleCliente } = useAuth();

	function handleClick() {
		if (!props.skeleton && props.data) {
			handleCliente(props.data);
			navigate("/dashboard");
		}
	}

	let skeletonButton = "";
	if (props.skeleton) {
		skeletonButton = "skeleton skeleton-button";
	}

	return (
		<>
			<ListCard onClick={handleClick}>
				<ListCard.Content>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Cliente</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Número de Voos</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.numeroVoos ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Horas de Vôo</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.tempoVoo ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Distância de Vôo</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.distanciaVoo ?? "..."}</ListCard.Text>
					</ListCard.Item>
				</ListCard.Content>
				<ListCard.Action>
					<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`} />
				</ListCard.Action>
			</ListCard>
		</>
	);
}
