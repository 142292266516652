import { Alert, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import moment from "moment";
import Icon from "@mdi/react";
import { mdiBroadcastOff } from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import ApiService from "../../services/ApiService";
import { gridTemplate } from "../../config/defines";
import { ApiResponseType } from "../../entities/ApiResponseEntity";
import { StatusVooModel } from "../../models/StatusVooModel";
import { NamedEntity } from "../../models/NamedEntity";

import LiveCard from "../../components/LiveCard";
import Layout from "../../components/Layout";

export default function Lives() {
	const { cliente, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);
	const dateEnd = moment();

	const queryDrones = useQuery(["drones", cliente!.id], () => fetchDataDrones(cliente!.id));
	const queryDroneStatusVoo = useQuery(["drones", "statusVoos", cliente!.id], () => fetchDataDronesStatusVoo(cliente!.id), { enabled: queryDrones.isFetched, refetchInterval: 1000 });

	async function fetchDataDrones(clienteId: any) {
		return await apiService.GetDronesCliente(clienteId);
	}

	async function fetchDataDronesStatusVoo(clienteId: any) {
		let resp: { drone: NamedEntity; statusVoo: ApiResponseType<StatusVooModel> }[] = [];
		if (queryDrones.data?.Data) {
			await Promise.all(
				queryDrones.data?.Data.map(async (drone, index) => {
					let respDroneStatusVoo = await apiService.getDroneStatusVoo(drone.id, clienteId);
					resp[index] = { drone: drone, statusVoo: respDroneStatusVoo };
				})
			);
		}
		return resp;
	}

	const lives = queryDroneStatusVoo.data?.filter((data) => {
		let dateStatus = false;
		if (data.statusVoo.Data?.data.ticks) {
			let dateStart = moment(data.statusVoo.Data?.data.ticks);
			let hours = moment.duration(dateEnd.diff(dateStart)).asHours();
			if (hours < 24) {
				dateStatus = true;
			}
		}
		return data.statusVoo.Data && dateStatus && data.statusVoo.Data.voando === 1;
	});
	const livesGrid = gridTemplate(lives?.length ?? 0);

	return (
		<Layout>
			<div className="my-4" style={{ display: "grid", gridTemplateColumns: livesGrid.gridTemplateColumns, minHeight: "calc(100dvh - 58px - 3rem)" }}>
				{queryDrones.isLoading || queryDroneStatusVoo.isLoading ? (
					<div className="d-flex justify-content-center align-items-center">
						<Spinner />
					</div>
				) : lives && lives.length >= 1 ? (
					lives.map((data, index) => {
						return (
							<div key={data.drone.id} style={{ gridColumn: livesGrid.gridColumns[index] }}>
								<LiveCard drone={data.drone} />
							</div>
						);
					})
				) : (
					<Alert variant="light" className="text-center p-4 d-flex flex-column justify-content-center align-items-center">
						<Icon path={mdiBroadcastOff} size={4} className="me-2" />
						Nenhuma Live no momento.
						<span className="text-muted mt-3 small">Quando houver Lives, elas serão exibidas automaticamente.</span>
					</Alert>
				)}
			</div>
		</Layout>
	);
}
